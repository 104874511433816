import React from "react";

interface Props { }
export const Privacy: React.FC<Props> = () => {
    return (
        <>
            <p className="c1"><span className="c0">Effective Date: 29 June 2020</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h2 className="c7" id="h.k3zkzpyara6n"><span className="c17">1. WHO IS RESPONSIBLE FOR PROCESSING YOUR DATA AND HOW TO CONTACT
                US</span></h2>
            <p className="c1"><span className="c0">This Privacy Policy applies to all of our games, websites and related services,
                collectively referred here as our Services. Your continued use of the Services after the effective date will be
                subject to new Privacy Policy.</span></p>
            <p className="c1"><span className="c0">Please read the following to learn more about how we use your personal
                information.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">For the purpose of the General Data Protection Regulation 2016/679 and any implementing
                legislation (the "GDPR"), iDream Interactive, Inc. (referred to as “iDream”, “we”, “us”, or “our”) will be the
                data controller responsible for any personal data we process.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">If you have any questions or concerns about this Privacy Policy, including those related
                to exercise any of your rights, please contact us through the game’s Help &amp; Support so we can reply to you
                more quickly.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">The data controller: iDream Interactive, Inc.</span></p>
            <p className="c1"><span>Email: </span><span className="c0">privacy@idreaminteractive.com</span></p>
            <p className="c1"><span className="c0">Address: 205-1501 Howard Ave., Windsor, Ontario, Canada N8X 3T5</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h2 className="c7" id="h.f7fuh6hj3u1q"><span className="c17">2. WHAT PERSONAL DATA WE COLLECT AND WHY?</span></h2>
            <p className="c1"><span className="c0">We may source, use and otherwise process your personal data in different ways. In all
                cases we are committed to protecting your personal data.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">In each of the sections listed below, we describe how we obtain your personal data and
                how we treat it.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h3 className="c10" id="h.kbeve75b4jh1"><span className="c13">2.1 INDIVIDUAL CUSTOMERS</span></h3>
            <p className="c1"><span className="c0">We collect personal data related to users of our Services.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.7qjyir44s2n"><span className="c4">A - SOURCES OF PERSONAL DATA</span></h4>
            <p className="c1"><span className="c0">We may obtain your personal data from the following sources:</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">a) from you directly (through the game, website or online forms); and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) from third parties, service providers that are assisting us in providing you with a
                service, or from your social networks accounts you used to sign in to our online services (for example,
                Facebook, Google, Game Circle, Weibo).</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.v60ognjig81u"><span className="c4">B - PERSONAL DATA THAT WE COLLECT AND PROCESS</span></h4>
            <p className="c1"><span className="c0">We may collect the following categories of personal data relating to our existing or
                prospective individual customers:</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">a) Contact information (such as name or nickname or email you submit as part of your
                register for our Services or log-in or via social networks);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) Data about your account and game progress, we create an iDream-specific ID for you
                when you use the Services;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">c) Your IP address and unique mobile device identification numbers (such as your device
                ID, advertising ID, MAC address);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">d) Data about your device, such as manufacturer, operating system, CPU, RAM, browser type
                and language;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">e) Broad location data (e.g. country or city-level location);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">f) Precise geolocation data (GPS, with your consent);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">g) Data we collect with cookies and similar technologies Cookies Notice;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">h) Data (such as your nickname, profile picture) we receive if you link a third party
                tool with the Service (such as Facebook, Google or Weibo);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">i) details of orders (amount spent, date, time, vouchers or offers used);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">j) Data to fight fraud (such as refund abuse in games or click fraud in
                advertising);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">k) Data from platforms that the games run on (such as to verify payment);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">l) Data for advertising and analytics purposes, so we can provide you a better
                Service;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">m) Your messages to the Services (such as chat logs and player support tickets) any
                feedback you submitted about your experience with us; and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">n) Other data you choose to give us.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.kievb3vd6cwr"><span className="c4">C - WHY DO WE COLLECT YOUR PERSONAL DATA AND WHAT ARE OUR LAWFUL
                BASES FOR IT?</span></h4>
            <p className="c16"><span className="c0">Individual Customers</span></p>
            <p className="c1 c3"><span className="c0"></span></p><a id="t.acf3be0f06719368334bfbf9dcb7a5ef21940092"></a><a id="t.0"></a>
            <table className="c9">
                <tbody>
                    <tr className="c12">
                        <td className="c21" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">We may use your personal data to:</span></p>
                        </td>
                        <td className="c20" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our lawful basis for doing so is:</span></p>
                        </td>
                    </tr>
                    <tr className="c12">
                        <td className="c21" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Provide you with our products or services (for example, play one of our
                                online games and make online purchases)</span></p>
                        </td>
                        <td className="c20" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Contract</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1 c3"><span className="c0"></span></p><a id="t.ee195dd2ec85c5e314d1091f9a922c3219e0ad2d"></a><a id="t.1"></a>
            <table className="c9">
                <tbody>
                    <tr className="c12">
                        <td className="c18" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">We may use your personal data to:</span></p>
                        </td>
                        <td className="c6" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our lawful basis for doing so is:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our legitimate interests in doing so are:</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c18" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Establish and manage our relationship (this covers making your experience
                                with us personalised, dealing with complaints or maintaining your account with us)</span></p>
                        </td>
                        <td className="c6" colSpan={1} rowSpan={4}>
                            <p className="c1"><span className="c0">Legitimate Interest</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Account Management</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                            <p className="c1"><span className="c0">Exercise or defend legal claims</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c18" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Learn about how our products and services are or may be used (for
                                example, when we ask you to fill out surveys about the experience you had with us)</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Understand the market in which we operate</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c18" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Security (ensuring confidentiality of personal information or preventing
                                unauthorised access and modifications to our systems)</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Managing security, risk and fraud prevention</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c18" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Let you know about our products, services and events that may be of
                                interest to you by email or other forms of electronic communication</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Promote our goods and services</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">If you object to us using your personal data for the above purposes, including direct
                marketing, please let us know using the email address provided in section 1.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">Where we use your email to communicate marketing information to you we will seek your
                prior consent where required to do so by law.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">We do not knowingly collect or solicit personal data about or direct or target interest
                based advertising to anyone under the age of 16 or knowingly allow such persons to use our Services. If you are
                under 16, please do not send any data about yourself to us, including your name, address, telephone number, or
                email address. No one under the age of 16 may provide any personal data. If we learn that we have collected
                personal data about a child under age 16, we will delete that data as quickly as possible. If you believe that
                we might have any data from or about a child under the age of 16, please contact us.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.ivnv7aqotrgg"><span className="c4">D – HOW LONG DO WE KEEP YOUR PERSONAL DATA?</span></h4>
            <p className="c1"><span className="c0">We will process your personal data only for as long as is necessary for the purposes for
                which it was collected in connection with the provision of service to you, unless we have a legal right or
                obligation to retain the data for a longer period, or the data is necessary for the establishment, exercise or
                defence of legal claims.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h3 className="c10" id="h.ttopemdj9tda"><span className="c13">2.2 REPRESENTATIVES OF OUR EXISTING OR PROSPECTIVE CORPORATE
                CUSTOMERS, BUSINESS PARTNERS, AND VENDORS</span></h3>
            <p className="c1"><span className="c0">We may collect personal data related to employees, directors, authorised signatories, or
                other individuals associated with iDream’s existing or prospective corporate customers, business partners, and
                vendors.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.45rezui0dzq1"><span className="c4">A - SOURCES OF PERSONAL DATA</span></h4>
            <p className="c1"><span className="c0">We may obtain your personal data from the following sources:</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">a) from you directly,</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) from a company that employs you, if you are an employee of our existing or prospective
                customer, business partner, or vendor,</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">c) during networking events that we have either hosted, or sponsored, or attended;
                and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">d) from publicly available sources (for example, your company website or social media
                sites, such as LinkedIn).</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.e6y6m2qff9aa"><span className="c4">B - PERSONAL DATA THAT WE COLLECT AND PROCESS</span></h4>
            <p className="c1"><span className="c0">We may collect the following categories of personal data relating to our existing or
                prospective customers’, business partners’, and vendors’ employees, officers, authorised signatories, and other
                associated individuals:</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">a) name;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) business address;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">c) business email address;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">d) business telephone number; and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">e) job title.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.tppgjyluifg6"><span className="c4">C - WHY DO WE COLLECT YOUR PERSONAL DATA AND WHAT ARE OUR LAWFUL
                BASES FOR IT?</span></h4>
            <p className="c1 c3"><span className="c0"></span></p><a id="t.909f44fc4fb97da7fa5f2bb18e24aada2a75dc59"></a><a id="t.2"></a>
            <table className="c9">
                <tbody>
                    <tr className="c12">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">We may use your personal data to:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our lawful basis for doing so is:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our legitimate interests in doing so are:</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Provide you with our products or services or receive products or services
                                from you</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={5}>
                            <p className="c1"><span className="c0">Legitimate Interest</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Efficiently fulfil our contractual and legal obligations</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Establish and manage our relationship</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Efficiently fulfil our contractual and legal obligations</span></p>
                            <p className="c1"><span className="c0">Account Management</span></p>
                            <p className="c1"><span className="c0">Understand the market in which we operate</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                            <p className="c1"><span className="c0">Exercise or defend legal claims</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Learn about how our products and services are or may be used</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Understand the market in which we operate</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Security</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Managing security, risk and fraud prevention</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Let you know about our products, services and events that may be of
                                interest to you by letter, telephone, email or other forms of electronic communication</span>
                            </p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Promote our goods and services</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">If you object to us using your personal data for these purposes, including direct
                marketing, please let us know using game’s Help &amp; Support tool or via the email provided in section 1
                above.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">Where we use your email to communicate marketing information to you we will seek your
                prior consent where required to do so by law.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.i6k9ig7r8tya"><span className="c4">D – HOW LONG DO WE KEEP YOUR PERSONAL DATA?</span></h4>
            <p className="c1"><span className="c0">We will process your personal data only for as long as is necessary for the purposes for
                which it was collected in connection with your business relationship with us, unless we have a legal right or
                obligation to retain the data for a longer period, or the data is necessary for the establishment, exercise or
                defence of legal claims.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h3 className="c10" id="h.t06v0aslyyi4"><span className="c13">2.3 WEBSITE VISITORS</span></h3>
            <p className="c1"><span className="c0">We may obtain your personal data from the following sources:</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">a) from you directly (for example, at the time of subscribing to any services offered on
                our website, including but not limited to email mailing lists, interactive services, posting material);
                and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) from your device or browser.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">If you contact us, we may keep a record of that correspondence.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.n7fsu1nhhvuz"><span className="c4">B - PERSONAL DATA THAT WE COLLECT AND PROCESS</span></h4>
            <p className="c1"><span className="c0">a) browser type;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) information on the use of our website (for example, pages visited, geographical
                location, time spent on the website, online transactions);</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">c) cookie data (for more information please see our Cookie Notice;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">d) preferences regarding online marketing; and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">e) IP address.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.k182awuzlkli"><span className="c4">C - WHY DO WE COLLECT YOUR PERSONAL DATA AND WHAT ARE OUR LAWFUL
                BASES FOR IT?</span></h4>
            <p className="c16"><span className="c0">Website Visitors</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1 c3"><span className="c0"></span></p><a id="t.2bde896e7e1499012dffdbfced306706f7e91e7b"></a><a id="t.3"></a>
            <table className="c9">
                <tbody>
                    <tr className="c12">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">We may use your personal data to:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our lawful basis for doing so is:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our legitimate interests in doing so are:</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Provide our website services to you</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={6}>
                            <p className="c1"><span className="c0">Legitimate Interest</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Website Management</span></p>
                            <p className="c1"><span className="c0">Promote our goods and services</span></p>
                            <p className="c1"><span className="c0">Account Management</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Establish and manage our relationship</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Understand the market in which we operate</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                            <p className="c1"><span className="c0">Account Management</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Learn about our website(s) users’ browsing patterns and the performance
                                of our website(s)</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Website Management</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Security</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Managing security, risk and crime prevention</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Let you know about our products, services and events that may be of
                                interest to you by letter, telephone, email or other forms of electronic communication</span>
                            </p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Promote our goods and services</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Learn about how our products or services may be used</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Understand the market in which we operate</span></p>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">If you object to us using your personal data for the above purposes, including direct
                marketing, please contact us using the email address in section 1.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">Where we use cookies or similar technologies we will seek your prior consent where
                required to do so by law.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">Where we use your email to communicate marketing information to you we will seek your
                prior consent where required to do so by law.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.7ytzd5dq1aar"><span className="c4">D – HOW LONG DO WE KEEP YOUR PERSONAL DATA?</span></h4>
            <p className="c1"><span className="c0">We will keep your personal data only for as long as is necessary for the purposes for
                which it was collected in connection with your requests via our website or your use of our website.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h3 className="c10" id="h.s9hgupv5gx42"><span className="c13">2.4 JOB APPLICANTS</span></h3>
            <p className="c1"><span className="c0">We may collect personal data related to job applicants for positions advertised on our
                website.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.nxmjk6mdp87y"><span className="c4">A - SOURCES OF PERSONAL DATA</span></h4>
            <p className="c1"><span className="c0">We may obtain your personal data from the following sources:</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">a) from you directly;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) from a third party, for example, individual referrals or a recruitment agency;</span>
            </p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">c) via hard copy and web-based application forms;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">d) during networking events that we have either hosted, or sponsored, or attended;
                and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">e) from publicly available sources (for example, professional networks, such as
                LinkedIn).</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.yjkctwoe7q1f"><span className="c4">B - PERSONAL DATA THAT WE COLLECT AND PROCESS</span></h4>
            <p className="c1"><span className="c0">We may collect the following categories of personal data, which may differ, depending on
                the content of your CV or baseline documents you submit to us:</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">a) name;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) residence address;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">c) personal email address;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">d) telephone number;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">e) date of birth;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">f) career and education history;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">g) skills, experience, and qualifications;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">h) personal interests, languages spoken, questionnaire results;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">i) gender;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">j) names and contact details for references. Please note that it is your responsibility
                to obtain consent from your references prior to providing us personal information about them;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">k) current and historic salary details together with salary expectations;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">l) details of your current benefit entitlements;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">m) information about your entitlement to work in the country in which the iDream
                affiliate is located; and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">n) other information you choose to give us.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.q75bl4ywl9wa"><span className="c4">C - WHY DO WE COLLECT YOUR PERSONAL DATA AND WHAT ARE OUR LAWFUL
                BASES FOR IT?</span></h4>
            <p className="c16"><span className="c0">Job Applicants</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1 c3"><span className="c0"></span></p><a id="t.c497ddfe73e8e3ee55eca7a51c526b1f972d3d9d"></a><a id="t.4"></a>
            <table className="c9">
                <tbody>
                    <tr className="c12">
                        <td className="c19" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">We may use your personal data to:</span></p>
                        </td>
                        <td className="c14" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our lawful basis for doing so is:</span></p>
                        </td>
                    </tr>
                    <tr className="c12">
                        <td className="c19" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Check your eligibility to work in the country in which iDream affiliate
                                is located</span></p>
                            <p className="c1"><span className="c0">Where you provide us with information regarding your disability, we will
                                process it as part of our legal obligation to make reasonable adjustments for recruitment
                                process</span></p>
                        </td>
                        <td className="c14" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Legal obligation</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c1 c3"><span className="c0"></span></p><a id="t.f2a3d2c0b8fcc1f10b9b04b6fcfb01ec467292e4"></a><a id="t.5"></a>
            <table className="c9">
                <tbody>
                    <tr className="c12">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">We may use your personal data to:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our lawful basis for doing so is:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our legitimate interests in doing so are:</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Facilitate the selection process</span></p>
                            <p className="c1"><span className="c0">Assess and confirm your suitability for employment</span></p>
                            <p className="c1"><span className="c0">Communicate with you</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={3}>
                            <p className="c1"><span className="c0">Legitimate interests</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Talent Management (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Execute business process and internal management</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Management Reporting (including at an intra-group level)</span></p>
                        </td>
                    </tr>
                    <tr className="c11">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Safeguard the security of our infrastructure, premises, assets and office
                                equipment, including prevention of criminal activity, defending legal claims</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Managing security, risk and crime prevention</span></p>
                            <p className="c1"><span className="c0">Exercise or defend legal claims</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.4kjqvnp8agop"><span className="c4">D – HOW LONG DO WE KEEP YOUR PERSONAL DATA?</span></h4>
            <p className="c1"><span className="c0">We will keep and process your Personal Data only for as long as is necessary for the
                purposes for which it was collected. If you are successful and we hire you, we will keep your CV as part of your
                employee record for the duration of your employment with us. We will keep CVs and documents submitted by
                unsuccessful candidates for no longer than twelve months, unless we obtained their consent to keep it for
                longer.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h3 className="c10" id="h.e4hyi97hlbdy"><span className="c13">2.5 VISITORS TO OUR PREMISES</span></h3>
            <h4 className="c5" id="h.rksfpzh8ufna"><span className="c4">A - SOURCES OF PERSONAL DATA</span></h4>
            <p className="c1"><span className="c0">We may obtain your personal data from you directly and from our systems’ records.</span>
            </p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.3b208ctblbei"><span className="c4">B - PERSONAL DATA THAT WE COLLECT AND PROCESS</span></h4>
            <p className="c1"><span className="c0">a) name;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) business contact details;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">c) organisation;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">d) role;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">e) time and date of your visit; and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">f) image (for example, from CCTV cameras at our premises).</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.5k6u2suolf8o"><span className="c4">C - WHY DO WE COLLECT YOUR PERSONAL DATA AND WHAT ARE OUR LAWFUL
                BASES FOR IT?</span></h4>
            <p className="c16"><span className="c0">Visitors to our Premises</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1 c3"><span className="c0"></span></p><a id="t.84777fff03507b76696f9fec6a9e6f63321734ab"></a><a id="t.6"></a>
            <table className="c9">
                <tbody>
                    <tr className="c12">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">We may use your personal data to:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our lawful basis for doing so is:</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Our legitimate interests in doing so are:</span></p>
                        </td>
                    </tr>
                    <tr className="c12">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Security</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Legitimate Interest</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Managing security, risk and crime prevention</span></p>
                        </td>
                    </tr>
                    <tr className="c12">
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Maintain records of visitors to our premises</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Legitimate Interest</span></p>
                        </td>
                        <td className="c8" colSpan={1} rowSpan={1}>
                            <p className="c1"><span className="c0">Management Reporting</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">If you object to us using your personal data for the above purposes, please let us know
                using the email address provided in section 1.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h4 className="c5" id="h.1q3knt16274w"><span className="c4">D – HOW LONG DO WE KEEP YOUR PERSONAL DATA?</span></h4>
            <p className="c1"><span className="c0">We keep your personal data for as long as necessary to ensure security of visitors to our
                premises and as soon as it is no longer necessary, usually after 90 days, we delete it.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h2 className="c7" id="h.8t818arlalhp"><span className="c17">3. WHO DO WE SHARE YOUR PERSONAL DATA WITH</span></h2>
            <p className="c1"><span className="c0">We do not sell your personal data to third parties.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">Our Partner Organisations and Service Providers</span></p>
            <p className="c1"><span className="c0">We may disclose information about you to organisations that provide a service to us,
                ensuring that they are contractually obligated to keep your personal data confidential and will comply with the
                GDPR and other relevant data protection laws.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">We may share your information with the following types of service providers:</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">a) technical support providers who assist with our website and IT infrastructure,</span>
            </p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">b) third party software providers, including 'software as a service' solution providers,
                where the provider hosts the relevant personal data on our behalf;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">c) professional advisers such as solicitors, accountants, tax advisors, auditors and
                insurance brokers;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">d) providers that help us generate and collate reviews in relation to our goods and
                services;</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">e) our advertising and promotional agencies and consultants and those organisations or
                online platforms selected by us to carry out marketing campaigns on our behalf and to advertise their own
                products or services that may be of interest to you; and/or</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">f) service providers that assist us in providing our services.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">Law enforcement or government bodies</span></p>
            <p className="c1"><span className="c0">We may disclose your personal data as permitted by law in order to investigate, prevent
                or take action regarding illegal activities, suspected fraud, violation of our intellectual property rights,
                situations involving potential threats to the physical safety of any person, violation of our Terms and
                Conditions or other agreements, or as required by law.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h2 className="c7" id="h.t1tgrt2tkrc8"><span className="c17">4. TRANSFERS OF PERSONAL DATA OUTSIDE THE EU/EUROPEAN ECONOMIC
                AREA</span></h2>
            <p className="c1"><span className="c0">We share your personal data with our affiliates in the EU and outside the EU.
                International transfers with iDream affiliates outside the EEA, are governed by EU Commission-approved Standard
                Contractual Clauses for Controllers and, where relevant, for Processors.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">We share personal data with external vendors or service providers or suppliers that we
                engage to perform services or functions on our behalf and under our instructions. Where these vendors are
                located within the EU, we ensure that they are contractually obligated to comply with the EU data protection
                rules. We also ensure in our contracts with these organisations that they only Process Personal Data in
                accordance with our instructions and in order to provide the agreed services and protect the integrity and
                confidentiality of your personal data entrusted to them.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">We may also disclose personal data to our advisers, consultants, law enforcement and
                other public authorities (such as tax and social security bodies), the police, prosecutors, courts and
                tribunals. All these recipients are themselves responsible to comply with the EU data protection rules.</span>
            </p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">Some of the vendors that we engage to are located outside the European Economic Area.
                Where the EU Commission did not recognise them as locations providing adequate protection for personal data, we
                sign the EU Commission-approved contract (so called Standard Contractual Clauses) to protect your data.</span>
            </p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">You may request a copy of these agreements by contacting us using the email address in
                section 1.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <h2 className="c7" id="h.4lorfp8j1leg"><span className="c17">5. YOUR RIGHTS</span></h2>
            <p className="c1"><span className="c0">You are entitled to obtain information from us on how we handle your personal data, to
                see copies of all personal data held by us and to request that your personal data is amended, corrected or
                deleted from our systems. You can also limit, restrict or object to the processing of your data.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">We do not carry out any decision-making based solely on automated processing, including
                profiling.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">If you gave us your consent to use your data, e.g. so that we can send you marketing
                emails or display personalised ads, you can withdraw your consent at any time. Please note that even if you
                withdraw your consent, we can still rely on the consent you gave as the lawful basis for processing your
                personal data before you withdrew your consent.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">You can object to our use of your personal data where we stated we rely on our legitimate
                business interests to do so. We explained the legitimate interests we rely on in sections 'Why do we collect
                your personal data and what are our lawful bases for it?' above.</span></p>
            <p className="c1 c3"><span className="c0"></span></p>
            <p className="c1"><span className="c0">If you would like to exercise any of your above rights, contact us using the contact
                details in section 1 above.</span></p>
        </>
    );
}

